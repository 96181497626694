
//run getVisitCount when page is loaded
window.addEventListener('DOMContentLoaded', event => {
    getVisitCount();
})

const functionApi = 'https://azure-node2.azurewebsites.net/api/update?code=AoMKsvqLmbY1Y5E8zTpjsqc5ZADqGc32VrRhqo5-zOHBAzFuxOyiMw==';

function getVisitCount() {
    let count = 30;
    fetch(functionApi)
    .then(response => response.json())
    .then(result => {
        console.log("website called function api");
        count = result.count;
        document.getElementById("counter").innerText = count;
        document.getElementById("counter-container").style.visibility = "visible";
    })
    .catch(err => console.log(err));
    return count;
};
