import * as THREE from 'three'
import * as dat from 'lil-gui'
import gsap from 'gsap'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import Swiper from 'swiper';

THREE.ColorManagement.enabled = false

/**
 * Debug
 */
const gui = new dat.GUI({ autoPlace: false })
const guiContainer = document.getElementById('gui-container');
guiContainer.appendChild(gui.domElement);

const parameters = {
    materialColor: '#FFF2F2'
}

gui
    .addColor(parameters, 'materialColor')
    .onChange(() => {
        material.color.set(parameters.materialColor)
        particlesMaterial.color.set(parameters.materialColor)
    })
/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * objects
 */
//Texture
const textureLoader = new THREE.TextureLoader()
const gradientTexture = textureLoader.load('textures/gradients/3.jpg')
gradientTexture.magFilter = THREE.NearestFilter

//Material
//meshtoon is light based material, so if we don't have lights, itll be pitch black
const material = new THREE.MeshToonMaterial({ 
    color : parameters.materialColor, 
    gradientMap: gradientTexture //gradient is very small (3pixels) instead of choosing pixels according to light, webgl will try to merge them
})

//Meshes
const objectDistance = 4
const mesh1 = new THREE.Mesh(
    new THREE.TorusGeometry(0.7, 0.3, 16, 60),
    material
    // new THREE.MeshBasicMaterial({ color : '#ff0000' })
)
const mesh2 = new THREE.Mesh(
    new THREE.ConeGeometry(0.7, 1.5, 32),
    material
)

const mesh3 = new THREE.Mesh(
    new THREE.TorusKnotGeometry(0.55, 0.2, 70, 10),
    material
)
const mesh4 = new THREE.Mesh(
    new THREE.BoxGeometry(1, 1, 1),
    material
)

mesh1.position.y = -objectDistance * 0
mesh2.position.y = -objectDistance * 1.05
mesh3.position.y = -objectDistance * 3.4
mesh4.position.y = -objectDistance * 3.3

mesh1.position.x = 1.7
mesh2.position.x = -1.5
mesh3.position.x = -1.5
mesh4.position.x = -1.5

scene.add(mesh1, mesh2, mesh3)

const sectionMeshes = [mesh1, mesh2, mesh3]



/**
 * Particles
 */
//Geometry
const particlesCount = 400
const positions = new Float32Array(particlesCount * 3) //need x,y,z (3) per particle

for(let i=0; i < particlesCount; i++) {
    positions[i * 3 + 0] = (Math.random() - 0.5) * 10
    positions[i * 3 + 1] = objectDistance * 0.5 - Math.random() * objectDistance * (sectionMeshes.length + 1)
    positions[i * 3 + 2] = (Math.random() - 0.5) * 10
}

const particlesGeometry = new THREE.BufferGeometry()
particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))

//Material
const particlesMaterial = new THREE.PointsMaterial({
    color: parameters.materialColor,
    sizeAttenuation: true, //particles further away are smaller 
    size: 0.025
})

//Points
const particles = new THREE.Points(particlesGeometry, particlesMaterial)
scene.add(particles)

/** 
 * Lights
 */
const directionalLight = new THREE.DirectionalLight('#ffffff', 1)
directionalLight.position.set(1, 1, 0)
scene.add(directionalLight)


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
//Group
const cameraGroup = new THREE.Group()
scene.add(cameraGroup)

// Base camera
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
camera.position.z = 6
cameraGroup.add(camera)

/**
 * Controls
 */
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true


/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true //clear alpha default value is t0
})
renderer.outputColorSpace = THREE.LinearSRGBColorSpace
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Scroll
 */
//scroll value
let scrollY = window.scrollY
let currentSection = 0

window.addEventListener('scroll', () => {
    //need to update it whenever it changes
    scrollY = window.scrollY

    const newSection = Math.round(scrollY / sizes.height) //this will add 1 everytime it reaches a new section
    //use math round becuz when we go half way, we can already see the next section 
    if (newSection != currentSection){
        currentSection = newSection 
        if (currentSection > 2) {
            currentSection = 2
        }
        gsap.to(
            sectionMeshes[currentSection].rotation,
            {
                duration: 1.5,
                ease: 'power2.inOut',
                x: '+= 6',
                y: '+= 5',
                z: '+= 3'
            }
        )
    }

}) 

//parallax is seeing on object through diff observation points
//we will use cursor to adjust camera to create parallax 
/**
 * Cursor
 */
const cursor = {
    x: 0, 
    y: 0
}
window.addEventListener('mousemove', (event) => {
    //want to keep it in range from 0 to 1 then change it range it into the same amount of positive and negative
    //we want this becuz we want to adjust the camera according to it, this will create a better view
    cursor.x = event.clientX / sizes.width - 0.5
    cursor.y = event.clientY / sizes.height - 0.5
})


/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    //delta time to ensure every user gets the same speed of parallax movement
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    //Animate Camera
    camera.position.y = -scrollY / sizes.height * objectDistance

    const parallaxX = cursor.x
    const parallaxY = - cursor.y //negative to make xy sync
    cameraGroup.position.x += (parallaxX*0.5 - cameraGroup.position.x) * deltaTime * 1.3
    cameraGroup.position.y += (parallaxY*0.5 - cameraGroup.position.y) * deltaTime * 1.3

    //Animate permanent rotation to meshes array
    for (const mesh of sectionMeshes) 
    {
        mesh.rotation.x += deltaTime * 0.12
        mesh.rotation.y += deltaTime * 0.15
        
    }
    // controls.update()
    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()

window.addEventListener('load', () => {
    // Hide the loading screen
    const loadingScreen = document.getElementById('loading-screen');
    loadingScreen.style.display = 'none';

    document.getElementById('hidden').style.display = "";

    // Show the content by removing the .hidden class
    // const content = document.querySelectorAll('.hidden');
    // content.forEach(element => {
    //     element.classList.remove('hidden');
    // });

    // Your existing code for initializing the scene, camera, renderer, and animation
});

  


