const modalInfo = [
  {
    title: "Pokechat",
    info: "PokeChat is a 3D virtual chat space that allows multiple users to interact with each other in a 3D pokemon themed world. There are also various mini games on the game menu that you can play with!",
    tech: "React, Three.js, Spine3D, Node.js, Socket.io, Express.js",
    link: "https://pokechat-biancafu.vercel.app/",
    github: "https://github.com/biancafu/Pokechat"
  },
  {
    title: "LegalEase",
    info: "A project created for the 2023 Dropbox Sign API Hackathon. Using GPT-3.5 API for instant transcription and document creation, and sharing via Dropbox API.",
    tech: "Flask, React, OpenAI, Vite, Dropbox Sign API",
    link: "https://devpost.com/software/legalease-automating-legal-document-creation-through-voice?ref_content=user-portfolio&ref_feature=in_progress",
    github: "https://github.com/biancafu/dropbox_sign"
  },{
    title: "PlanetBnB",
    info: "Planet BnB: Cosmic Gateways brought to you by Stellar Odyssey. A project created for the 2023 NASA Space Challenge.",
    tech: "React, Spline3D, Midjourney",
    link: "https://stellar-odyssey.vercel.app/",
    github: "https://github.com/biancafu/stellar-odyssey"
  },{
    title: "On The Run",
    info: "On The Run is a game that allows players tto collect items and reach their destination while navigating a multitude of challenges and obstacles.",
    tech: "Pygame, Python",
    link: "https://devpost.com/software/multiverse-chase",
    github: "https://github.com/biancafu/pygame_hackathon"
  },
  {
    title: "Interview Scheduler",
    info: "An application for students to create, edit and delete interview appointments with mentors",
    tech: "React, Node.js, Express, PostgreSQL, Storybook, SASS, Jest, Webpack, Cypress",
    link: "",
    github: "https://github.com/biancafu/scheduler"
  },
  {
    title: "Food Ordering",
    info: "A Food ordering site that provides both customer and restaurant a platform to connect. Customers can place orders from the website and restaurant will receive the order details.",
    tech: "Node.js, HTML, EJS, SCSS, PostgreSQL",
    link: "",
    github: "https://github.com/jsc604/foodOrdering"
  },
  {
    title: "Jungle",
    info: " Jungle enables customers to browse the options that are available in the store whereby they can add and remove items into a cart. Customers are be able to create account or login at anytime. It also has an admin page that allows store employees to add more new products.",
    tech: "Rails, Ruby on Rails, PostgreSQL, Stripe, Bootstrap, Cypress",
    link: "",
    github: "https://github.com/biancafu/jungle"
  },
  {
    title: "Flappy Bird AI",
    info: "This is a machine learning project where we created an AI using NEAT to play the flappy bird game.",
    tech: "Pygame, Python, NEAT",
    link: "",
    github: "https://github.com/biancafu/flappy_bird_ai"
  },
]

const len = Object.keys(modalInfo).length
const modal = document.getElementById('project-descriptions');

// button that opens the modal
const img = document.getElementsByClassName("swiper-slide");
const next = document.getElementsByClassName("swiper-button-next");
const prev = document.getElementsByClassName("swiper-button-prev");
let counter = 0

document.getElementById("intro_github").onclick = function () {
  window.open('https://github.com/biancafu', '_blank');
}
document.getElementById("intro_linkedin").onclick = function () {
  window.open('https://www.linkedin.com/in/biancafu/', '_blank');
}

fillOut(counter)
// open modal 
for (let i = 0; i < img.length; i++) {
  img[i].addEventListener("click", function () {
    counter = i % len;
    fillOut(counter);
  })
};
next[0].addEventListener("click", () => {
  counter = (counter + 1) % len;
  fillOut(counter);
})
prev[0].addEventListener("click", () => {
  counter = (counter - 1) % len;
  fillOut(counter);
})

function fillOut(id) {
  document.getElementById("title").innerHTML = modalInfo[id].title;
  document.getElementById("info").innerHTML = modalInfo[id].info;
  document.getElementById("tech").innerHTML = modalInfo[id].tech;
  if (modalInfo[id].link) {
    document.getElementById("live").style.display = "block";
    document.getElementById("live").onclick = function () {
      window.open(modalInfo[id].link, '_blank');
    }
  }
  else {
    document.getElementById("live").style.display = "none";
  }

  document.getElementById("github").onclick = function () {
    window.open(modalInfo[id].github, '_blank');
  }
}
